"use strict";
module.exports = {
    "en": {
        "The popup has been closed by the user before finalizing the operation": {
            "": "The user has closed the popup before completing the operation."
        },
        "The SMS verification code used to create the phone auth credential is invalid": {
            "": {
                " Please resend the verification code sms and be sure to use the verification code provided by the user": {
                    "": "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user."
                }
            }
        },
        "none_campaign": "No campaigns yet",
        "The link must be required": {
            "": "You haven't entered a shortened link."
        },
        "Time must be more than 24 hours": {
            "": "The time must be more than 24 hours."
        },
        "Time must be greater than present": {
            "": "The time must be greater than the present time."
        },
        "Invalid date": {
            "": "The date is invalid."
        },
        "Invalid time": {
            "": "The time is invalid."
        },
        "-": "( Not defined )",
        "norequire": "( not required )",
        "menu": "Menu",
        "home": "Home",
        "custom": "Custom",
        "copied": "Link copied successfully",
        "copy": "Copy Link",
        "new_users": "New users",
        "Delete": "Delete",
        "apply": "Apply",
        "Accept": "Accept",
        "Cancel": "Cancel",
        "link_failed_create_time": "You are creating data too quickly, each operation must be separated by {{value}} seconds.",
        "link_failed_update_time": "You are updating data too quickly, each operation must be separated by {{value}} seconds.",
        "edit": "Edit",
        "statistical": "Statistical",
        "redirect": "Redirect",
        "shortlink": "Shortlink",
        "value_views": "{{value}}<br />views",
        "value_users": "{{value}}<br />users",
        "views": "views",
        "viewer": "viewer",
        "today": "Today",
        "yesterday": "Yesterday",
        "all": "All",
        "{{value}} days": "{{value}} days",
        "{{value}}%": "{{value}}%",
        "{{value}}": "{{value}}",
        "status_config": "Status configuration",
        "enter_link": "Enter the link you want to shorten.",
        "enter_phone_number": "Please enter the phone number.",
        "phone_minimum": "The phone number must be at least {{value}} characters long.",
        "phone_too_long": "The phone number is too long.",
        "phone_already_exists": "The phone number already exists.",
        "state_per_page": "States per page",
        "SCAN ME": "Scan me",
        "share": "Share",
        "Social text": "Social text",
        "Card": "Card",
        "Text": "Text",
        "qrcode": "Qr Code",
        "country": "Country",
        "country_back": "< Country",
        "country_per_page": "Countries per page",
        "close": "Close",
        "cancel": "Cancel",
        "agree": "Agree",
        "accept": "Accept",
        "total": "Total",
        "shorted": "Shortened",
        "link": "Link",
        "label": {
            "popup": {
                "datetime": "Select time range"
            },
            "links": {
                "filter": "Sort by"
            }
        },
        "users": {
            "new": "New users",
            "back": "Returning users"
        },
        "choose": {
            "num": "You have selected {{num}}",
            "action": "Select action"
        },
        "status": {
            "empty": "No data",
            "loading": "Loading data"
        },
        "date": {
            "all": "All",
            "today": "Today",
            "yesterday": "Yesterday",
            "day": "{{value}} days"
        },
        "address": {
            "address": {
                "zone": "Province/City",
                "locality": "District/County",
                "dependentlocality": "Ward/Town",
                "address": "Detailed address"
            },
            "card": {
                "edit": "Edit card"
            },
            "location": {
                "column": {
                    "name": "Name",
                    "view": "Viewers"
                }
            },
            "login": {
                "form": {
                    "title": "Login",
                    "field": {
                        "usernameOrEmail": "Email address",
                        "pass": "Password"
                    },
                    "btnSubmit": "Login"
                },
                "btn": {
                    "title": "Login",
                    "forgotPass": "Forgot password"
                }
            },
            "stats": {
                "column": {
                    "name": "Channel",
                    "view": "Views",
                    "users": "Viewers",
                    "newUsers": "New viewers"
                }
            },
            "list": {
                "title": "List",
                "detail": "View details",
                "devices": {
                    "name": "Name",
                    "qty": "Quantity",
                    "view": "Views",
                    "percent": "Percentage"
                },
                "campaign": {
                    "name": "Campaign name"
                },
                "location": {
                    "name": "Name",
                    "view": "Views",
                    "country": "Country",
                    "city": "City"
                },
                "column": {
                    "name": "Title",
                    "info": "Shortened link",
                    "view": "Views",
                    "created": "Created date",
                    "auth": "Creator",
                    "actions": "Actions",
                    "link": "Link",
                    "type": "Type"
                },
                "btn": {
                    "add": "Create shortened link",
                    "edit": "Edit",
                    "copy": "Copy link",
                    "share": "Share",
                    "view": "View link",
                    "delete": "Delete link",
                    "qr_edit": "QR Code",
                    "person_add": "Add manager",
                    "move": "Move campaign",
                    "unpublished": "Unpublish link"
                },
                "actions": {
                    "published": "Published",
                    "unpublished": "Unpublished",
                    "move": "Move",
                    "delete": "Delete"
                }
            },
            "register": {
                "confirmationMsgTitle": "Please verify your email address.",
                "confirmationMsgBody": "We need to verify your email address. We have sent you an email. Please click on the link in that email to continue.",
                "confirmationBtn": "Return to login page",
                "successRegTitle": "Email verification successful.",
                "successRegBody": "Your email has been verified successfully. You can log in with your login credentials.",
                "errorMsg": "Registration failed!",
                "title": "Register",
                "meta": "Registration page",
                "form": {
                    "title": "Register",
                    "field": {
                        "displayName": "Full name",
                        "usernameOrEmail": "Username or email",
                        "name": "User email",
                        "email": "Email",
                        "pass": "Password",
                        "passConf": "Confirm password"
                    }
                },
                "btn": {
                    "forgotPass": "Forgot password?",
                    "notReg": "Not registered yet?",
                    "sign": "Register"
                }
            },
            "image": {
                "size": {
                    "square": "Square",
                    "horizontal": "Horizontal",
                    "vertical": "Vertical"
                }
            },
            "profile": {
                "form": {
                    "field": {
                        "phone": "Phone number"
                    }
                }
            },
            "none": {
                "note": "No notes",
                "categories": "No campaigns"
            },
            "dashboard": {
                "menu": "Overview",
                "data_by_brand_domain": "Data by brand domain"
            },
            "guest": {
                "shorted": "Shortened"
            }
        }
    },
    "vi": {
        "The popup has been closed by the user before finalizing the operation": {
            "": "Bạn đ\xe3 đ\xf3ng cửa sổ bật l\xean trước khi ho\xe0n tất thao t\xe1c."
        },
        "The SMS verification code used to create the phone auth credential is invalid": {
            "": {
                " Please resend the verification code sms and be sure to use the verification code provided by the user": {
                    "": "M\xe3 x\xe1c minh SMS được sử dụng để tạo th\xf4ng tin x\xe1c thực điện thoại kh\xf4ng hợp lệ. Vui l\xf2ng gửi lại sms m\xe3 x\xe1c minh v\xe0 đảm bảo sử dụng m\xe3 x\xe1c minh do người d\xf9ng cung cấp."
                }
            }
        },
        "none_campaign": "Chưa c\xf3 chiến dịch",
        "The link must be required": {
            "": "Bạn chưa nhập link r\xfat gọn."
        },
        "Time must be more than 24 hours": {
            "": "Thời gian phải lớn hơn 24 tiếng."
        },
        "Time must be greater than present": {
            "": "Thời gian phải lớn hơn hiện tại."
        },
        "Invalid date": {
            "": "Ng\xe0y th\xe1ng kh\xf4ng hợp lệ."
        },
        "Invalid time": {
            "": "Nhập giờ kh\xf4ng hợp lệ."
        },
        "Name is required": "T\xean l\xe0 bắt buộc",
        "-": "( Kh\xf4ng x\xe1c định )",
        "norequire": "( kh\xf4ng bắt buộc )",
        "menu": "Menu",
        "home": "Trang chủ",
        "custom": "T\xf9y chỉnh",
        "copied": "Copy Link th\xe0nh c\xf4ng",
        "copy": "Copy Link",
        "new_users": "Người d\xf9ng mới",
        "Delete": "Xo\xe1",
        "apply": "\xc1p dụng",
        "Accept": "Đồng \xfd",
        "Cancel": "Huỷ",
        "link_failed_create_time": "Bạn tạo dữ liệu qu\xe1 nhanh, mỗi lần thao t\xe1c phải c\xe1ch nhau {{value}} gi\xe2y.",
        "link_failed_update_time": "Bạn cập nhật dữ liệu qu\xe1 nhanh, mỗi lần thao t\xe1c phải c\xe1ch nhau {{value}} gi\xe2y.",
        "edit": "Chỉnh sửa",
        "statistical": "Thống k\xea",
        "redirect": "Điều hướng",
        "shortlink": "R\xfat Gọn Link",
        "back": "Quay lại",
        "value_views": "{{value}}<br />lượt vinhxuan",
        "value_users": "{{value}}<br />người vinhxuan",
        "views": "lượt vinhxuan",
        "viewer": "người vinhxuan",
        "today": "H\xf4m nay",
        "yesterday": "H\xf4m qua",
        "all": "Tất cả",
        "{{value}} days": "{{value}} ng\xe0y",
        "{{value}}%": "{{value}}%",
        "{{value}}": "{{value}}",
        "status_config": "Cấu h\xecnh status đẹp",
        "enter_link": "Nhập link bạn muốn r\xfat gọn.",
        "enter_phone_number": "H\xe3y nhập số điện thoại.",
        "phone_minimum": "Số điện thoại phải c\xf3 \xedt nhất {{value}} k\xfd tự.",
        "phone_too_long": "Số điện thoại qu\xe1 d\xe0i.",
        "phone_already_exists": "Số điện thoại đ\xe3 tồn tại.",
        "state_per_page": "Trạng th\xe1i tr\xean mỗi trang",
        "SCAN ME": "Qu\xe9t đi chờ chi",
        "share": "Chia sẻ",
        "Social text": "Social text",
        "Card": "Card",
        "Text": "Text",
        "qrcode": "Qr Code",
        "country": "Quốc gia",
        "country_back": "< Quốc gia",
        "country_per_page": "Quốc gia tr\xean mỗi trang",
        "close": "Đ\xf3ng",
        "cancel": "Huỷ",
        "agree": "Đồng \xfd",
        "accept": "Đồng \xfd",
        "total": "Tổng",
        "shorted": "Đ\xe3 r\xfat gọn",
        "link": "Link",
        "label": {
            "popup": {
                "datetime": "Tuỳ chọn phạm vi"
            },
            "links": {
                "filter": "Sắp Xếp Hiển Thị"
            }
        },
        "users": {
            "new": "Người d\xf9ng mới",
            "back": "Người d\xf9ng quay lại"
        },
        "choose": {
            "num": "Bạn đ\xe3 chọn {{num}}",
            "action": "Chọn thao t\xe1c"
        },
        "status": {
            "empty": "Kh\xf4ng c\xf3 dữ liệu",
            "loading": "Đang tải dữ liệu"
        },
        "date": {
            "all": "Tất cả",
            "today": "H\xf4m nay",
            "yesterday": "H\xf4m qua",
            "day": "{{value}} ng\xe0y"
        },
        "address": {
            "zone": "Tỉnh/ Th\xe0nh phố",
            "locality": "Quận/ Huyện",
            "dependentlocality": "Phường/ X\xe3",
            "address": "Địa chỉ chi tiết"
        },
        "card": {
            "edit": "Chỉnh sửa card"
        },
        "location": {
            "column": {
                "name": "T\xean",
                "view": "Người vinhxuan"
            }
        },
        "login": {
            "form": {
                "title": "Đăng nhập",
                "field": {
                    "usernameOrEmail": "Địa chỉ email",
                    "pass": "Mật khẩu"
                },
                "btnSubmit": "Đăng nhập"
            },
            "btn": {
                "title": "Đăng nhập",
                "forgotPass": "Qu\xean mật khẩu"
            }
        },
        "stats": {
            "column": {
                "name": "K\xeanh",
                "view": "Lượt vinhxuan",
                "users": "Người vinhxuan",
                "newUsers": "Người vinhxuan mới"
            }
        },
        "list": {
            "title": "Danh s\xe1ch",
            "detail": "Xem Chi Tiết",
            "devices": {
                "name": "T\xean",
                "qty": "Số lượng",
                "view": "Lượt vinhxuan",
                "percent": "Tỷ lệ"
            },
            "campaign": {
                "name": "T\xean chiến dịch"
            },
            "location": {
                "name": "T\xean",
                "view": "Lượt vinhxuan",
                "country": "Quốc gia",
                "city": "Th\xe0nh phố"
            },
            "column": {
                "name": "Ti\xeau đề",
                "info": "Link r\xfat gọn",
                "view": "Lượt vinhxuan",
                "created": "Ng\xe0y tạo",
                "auth": "Người tạo",
                "actions": "T\xe1c vụ",
                "link": "Link",
                "type": "Kiểu"
            },
            "btn": {
                "add": "Tạo link r\xfat gọn",
                "edit": "Chỉnh sửa",
                "copy": "Copy link",
                "share": "Chia sẻ",
                "view": "Xem link",
                "delete": "Xo\xe1 link",
                "qr_edit": "Qr Code",
                "person_add": "Th\xeam người quản l\xfd",
                "move": "Chuyển đổi chiến dịch",
                "unpublished": "Tạm ẩn link"
            },
            "actions": {
                "published": "C\xf4ng khai",
                "unpublished": "Ẩn",
                "move": "Di chuyển",
                "delete": "Xo\xe1"
            }
        },
        "register": {
            "confirmationMsgTitle": "X\xe1c minh địa chỉ email của bạn.",
            "confirmationMsgBody": "B\xe2y giờ ch\xfang t\xf4i cần x\xe1c minh địa chỉ email của bạn. Ch\xfang t\xf4i đ\xe3 gửi cho bạn email. Vui l\xf2ng nhấp v\xe0o li\xean kết trong email đ\xf3 để tiếp tục.",
            "confirmationBtn": "Quay lại trang Đăng nhập",
            "successRegTitle": "Email đ\xe3 được x\xe1c minh th\xe0nh c\xf4ng.",
            "successRegBody": "Email của bạn đ\xe3 được x\xe1c minh th\xe0nh c\xf4ng. Bạn c\xf3 thể đăng nhập bằng th\xf4ng tin đăng nhập v\xe0 mật khẩu của m\xecnh.",
            "errorMsg": "Đăng k\xfd thất bại!",
            "title": "Đăng k\xfd",
            "meta": "Trang đăng k\xfd",
            "form": {
                "title": "Đăng k\xfd",
                "field": {
                    "displayName": "Họ t\xean đầy đủ",
                    "usernameOrEmail": "T\xe0i khoản hoặc Email",
                    "name": "Email người d\xf9ng",
                    "email": "Email",
                    "pass": "Mật khẩu",
                    "passConf": "X\xe1c nhận mật khẩu"
                }
            },
            "btn": {
                "forgotPass": "Qu\xean mật khẩu?",
                "notReg": "Chưa đăng k\xfd?",
                "sign": "Đăng k\xfd"
            }
        },
        "image": {
            "size": {
                "square": "Vu\xf4ng",
                "horizontal": "Ngang",
                "vertical": "Dọc"
            }
        },
        "profile": {
            "form": {
                "field": {
                    "phone": "Số điện thoại"
                }
            }
        },
        "none": {
            "note": "Chưa c\xf3 ghi ch\xfa",
            "categories": "Chưa c\xf3 Chiến dịch"
        },
        "dashboard": {
            "menu": "Tổng quan",
            "data_by_brand_domain": "Dữ liệu theo t\xean miền thương hiệu"
        },
        "guest": {
            "shorted": "Kh\xe1ch r\xfat gọn"
        }
    }
};
