import moment from "moment";
import settings from "@vinhxuan/config";
class Profile {
    set name(value) {
        this._name = value;
    }
    get name() {
        return this._name || "";
    }
    constructor({ name , first_name , last_name , birthday , sex  }){
        // tslint:disable-next-line:variable-name
        this._name = "";
        // tslint:disable-next-line:variable-name
        this.first_name = "";
        // tslint:disable-next-line:variable-name
        this.last_name = "";
        this.birthday = moment();
        this.sex = "other";
        this.name = name;
        this.first_name = first_name;
        this.last_name = last_name;
        this.birthday = birthday;
        this.sex = sex || "other";
    }
}
class Address {
    constructor({ country , zone , locality , dependent_locality , address , address_2  }){
        this.country = country;
        this.zone = zone;
        this.locality = locality;
        this.dependentlocality = dependent_locality;
        this.address = address;
        this.address_2 = address_2;
    }
}
class Auth {
    constructor({ facebook , google  }){
        this.facebook = {};
        this.google = {};
        this.facebook = facebook || {};
        this.google = google || {};
    }
}
export class User {
    /**
   * instanse
   */ static instanse(data) {
        return new this().save(data || {});
    }
    get address() {
        return this._address;
    }
    set address(address) {
        this._address = new Address(address || {});
    }
    /**
   * profile
   */ get profile() {
        return this._profile;
    }
    set profile(profile) {
        this._profile = new Profile(profile || {});
    }
    get auth() {
        return this._auth;
    }
    set auth(auth) {
        this._auth = new Auth(auth || {});
    }
    get display_name() {
        return this.profile.name || "";
    }
    get first_name() {
        return this.profile.first_name || "";
    }
    get last_name() {
        return this.profile.last_name || "";
    }
    set phone(value) {
        try {
            let { phone: code , value: area  } = [
                ...settings.country.countries
            ].filter((param)=>{
                let { phone: code  } = param;
                return new RegExp("^".concat(code)).test(value.replace(/\D/g, ""));
            }).shift();
            this._area = area;
            this._phone = value.replace(/\D/g, "").replace(new RegExp("^".concat(code)), "");
        } catch (e) {}
    }
    get area() {
        return this._area || "VN";
    }
    get phone() {
        return this._phone || "";
    }
    set birthday(value) {
        // @ts-ignore
        this._birthday = value;
    }
    get birthday() {
        return this._birthday || null;
    }
    set sex(value) {
        this._sex = value;
    }
    get sex() {
        return this._sex;
    }
    get status() {
        return this._is_active || false;
    }
    get role() {
        return this.roles.filter((role)=>role !== "authenticated");
    }
    get roles() {
        return this._roles;
    }
    set roles(roles) {
        const rest = [];
        roles = typeof roles === "string" ? [
            roles
        ] : roles || [];
        roles.map((role)=>{
            if (!rest.includes(role)) {
                rest.push(role);
            }
        });
        this._roles = rest;
    }
    reset() {
        // tslint:disable-next-line:no-this-assignment
        const $self = this;
        Object.assign($self, User.instanse());
    }
    /**
   * {@inheritdoc}
   */ get accessUpdate() {
        return this._accessUpdate || false;
    }
    /**
   * {@inheritdoc}
   */ set accessUpdate(value) {
        this._accessUpdate = value || false;
    }
    /**
   * {@inheritdoc}
   */ get accessDelete() {
        return this._accessDelete || false;
    }
    /**
   * {@inheritdoc}
   */ set accessDelete(value) {
        this._accessDelete = value || false;
    }
    // @ts-ignore
    save() {
        let { id , image , photo , status , name , username , role , mail , phone , address , profile , ga , pixel , auth , perms , accessDelete , accessUpdate  } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const { birthday , sex  } = profile || {};
        this.ga = ga;
        this.pixel = pixel;
        this.id = id;
        this.mail = mail;
        this.name = name;
        this.username = username;
        this._is_active = status;
        this.sex = sex;
        this.birthday = birthday;
        this.phone = phone;
        this.roles = role || [
            "anonymous"
        ];
        this.auth = auth;
        this.address = address;
        this.profile = {
            ...profile,
            name
        };
        this.accessDelete = accessDelete;
        this.accessUpdate = accessUpdate;
        this.photo = photo;
        this.image = image;
        this.perms = perms;
        return this;
    }
    hasPerms(perms) {
        const $perms = this.perms || {};
        perms = typeof perms === "string" ? [
            perms
        ] : perms || [];
        return !!perms.filter((perm)=>$perms[perm]).length;
    }
    hasRole(roles) {
        const $roles = this.roles;
        roles = typeof roles === "string" ? [
            roles
        ] : roles || [];
        return !!roles.filter((role)=>$roles.includes(role)).length;
    }
    constructor(){
        this._roles = [];
        this._address = new Address({});
        this._profile = new Profile({});
        this._auth = new Auth({});
    }
}
export default User;
