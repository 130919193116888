"use strict";
module.exports = {
    "en": {
        "navLink": "Video nav link",
        "title": "Video module",
        "meta": "Video example",
        "welcomeText": "Hello, This is the Video module"
    },
    "vi": {
        "navLink": "Video nav link",
        "title": "Video module",
        "meta": "Video example",
        "welcomeText": "Hello, This is the Video module"
    }
};
