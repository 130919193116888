import settings from "@vinhxuan/config";
import Action from "@vinhxuan/entity/classes/Action";
const link = {
    list: "/links.html",
    detail: "/link/:id/view.html",
    create: "/link/short-link.html",
    taxonomyView: "/campaign/:id/links.html"
};
export const map = (param)=>{
    let { id , path  } = param;
    return "".concat(link[path]).replace(/:id/, id);
};
const defaultConfig = {
    ...settings.route
};
export const userConfigLogined = {
    ...defaultConfig,
    action: Action.instanse(),
    isDashboard: true,
    mobile: {
        show: true
    },
    redirectOnAnonymous: true,
    webType: "cpanel",
    redirect: "/login.html",
    classNameOnPage: "theme-mobile"
};
export const userConfigAnonymous = {
    ...defaultConfig,
    isHome: true,
    redirectOnLoggedIn: true,
    loading: false,
    redirect: "/dashboard.html"
};
