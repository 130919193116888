class Video {
    /**
   * instanse
   */ static instanse() {
        return new this();
    }
    /**
   * save
   */ save(param) {
        let { id , url , full , title , body , video_url , summary , tags , category , status , uid , author , image , created , changed  } = param;
        this.id = id;
        this.uid = uid;
        this.url = url;
        this.full = full;
        this.author = author;
        this.title = title;
        this.video_url = video_url;
        this.category = category;
        this.tags = tags;
        this.summary = summary;
        this.body = body;
        this.image = image || {};
        this.status = status;
        this.created = created;
        this.changed = changed;
    }
    constructor(){
        this.status = false;
    }
}
export { Video as default };
