import ClientModule from "@gqlapp/module-client-react";
import { ApolloLink } from "apollo-link";
// @ts-ignore
import { apiUrl } from "@vinhxuan/config/settings";
import { BatchHttpLink } from "apollo-link-batch-http";
import { extractFiles } from "extract-files";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";
export default new ClientModule({
    createNetLink: (apiLink)=>{
        return ApolloLink.split((param)=>{
            let { operationName: op  } = param;
            return [
                "login",
                "logout"
            ].includes("".concat(op).toLowerCase());
        }, new BatchHttpLink({
            uri: apiUrl,
            credentials: "include"
        }), ApolloLink.split((param)=>{
            let { variables  } = param;
            var ref, ref1;
            return ((ref = extractFiles(variables)) === null || ref === void 0 ? void 0 : (ref1 = ref.files) === null || ref1 === void 0 ? void 0 : ref1.size) > 0;
        }, // @ts-ignore
        createUploadLink({
            uri: apiUrl,
            credentials: "include"
        }), apiLink));
    }
});
