"use strict";
module.exports = {
    "en": {
        "navLink": "Photo nav link",
        "title": "Photo module",
        "meta": "Photo example",
        "welcomeText": "Hello, This is the Photo module"
    },
    "vi": {
        "navLink": "Photo nav link",
        "title": "Photo module",
        "meta": "Photo example",
        "welcomeText": "Hello, This is the Photo module"
    }
};
