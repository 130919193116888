import withProps from "recompose/withProps";
const stylesHeaders = {
    "& [data-header]": {
        "&[data-header-cpanel],&[data-header-link]": {
            zIndex: 1000,
            marginBottom: "15px",
            "& .header": {
                "boxShadow": "0px 1px 1px rgba(176, 176, 176, 0.21)"
            },
            "&~.view-main": {
                marginTop: "-15px",
                "& > .page": {
                    "& > .container-fluid": {
                        paddingTop: "15px"
                    }
                }
            }
        }
    },
    "& .modal": {
        "&.modal-id-font, &.download, &.modal-id-gradient-type": {
            "& .modal-content": {
                "--modal-background-color": "#ffffff"
            }
        },
        "& .modal-btn-close": {
            right: "15px !important",
            "& svg": {
                width: "24px !important",
                height: "24px !important"
            }
        },
        "&.font-family, &.colorful, &.download, &.gradient": {
            "& .modal-header": {
                border: "none"
            }
        },
        "& .modal-title": {
            fontSize: "18px"
        }
    },
    "& .MuiTabs-root": {
        lineHeight: "20px",
        minHeight: "40px",
        "& .MuiTab-root": {
            padding: 0,
            lineHeight: "20px",
            minHeight: "40px"
        }
    },
    "& .btn-tab": {
        color: "var(--btn-color,#686868)",
        "&.Mui-selected": {
            color: "var(--btn-selected-color, #0066CC)"
        }
    },
    "& .MuiTableCell-head, & .MuiTableCell-root": {
        color: "#212121"
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#0066CC",
        height: "3px"
    },
    "& .MuiOutlinedInput-input": {
        padding: "8.5px 10px"
    },
    "& .MuiInputBase-root": {
        color: "currentColor",
        "&.MuiInputBase-adornedEnd": {
            paddingRight: "10px"
        }
    },
    "& .MuiSelect-Color-Input, & .MuiSelect-Input": {
        // paddingRight: '10px',
        "& .MuiInputBase-input": {
            paddingRight: "5px"
        },
        "& .MuiSelect-Arrow-Dropdown": {
            width: "12px"
        },
        "& .MuiSelect-Color-Box": {
            width: "20px"
        }
    }
};
export default withProps({
    stylesHeaders
});
