import React, { FunctionComponent, ReactNode } from 'react';
import { useSwipeable } from '@gqlapp/base/Swipeable';
import { SwipeableHandlers } from '@gqlapp/base/Swipeable/types';
import PropTypes from 'prop-types';


// @ts-ignore
const Swipeable: FunctionComponent = ({ swipeProps, innerRef, ...props}: any) => {
	let handlers: SwipeableHandlers | (JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>);
	let swipeRef = React.useRef()


	if(swipeProps){
		const get = () =>{
			let current: any = swipeRef?.current;
			// @ts-ignore
			const hasScrollableContent = current?.scrollHeight > current?.clientHeight;
			const overflowYStyle = window.getComputedStyle(swipeRef?.current).overflowY;
			const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

			return {
				key: props?.mkey,
				el: current,
				hasScroll: hasScrollableContent && !isOverflowHidden,
				scrollTop: current?.scrollTop <= 0 ? 0: current?.scrollTop,
				isScrollTop: current?.scrollTop <= 0,
				isScrollBottom: current?.scrollTop + current?.clientHeight >= current?.scrollHeight,
				scrollMaxY: current?.scrollHeight - (current?.clientHeight + current?.scrollTop)
			}
		}

		/**
		 * Set
		 * @param type
		 */
		const set = ({ type }: any) => (state: any, {}: any) =>{
			let el = state?.el, hasScroll:boolean = false, hasScrollX: boolean = false, hasScrollY: boolean = false;
			let scrollTop = 0, isScrollTop = false, isScrollBottom = false;
			let scrollX = 0, isScrollMinX = false, isScrollMaxX = false;
			let scrollY = 0, isScrollMinY = false, isScrollMaxY = false;

			try{

				/** */
				const overflowYStyle = window.getComputedStyle(el).overflowY;
				let hasScrollableYContent = el.scrollHeight > el.clientHeight;
				hasScroll = (hasScrollableYContent && (overflowYStyle.indexOf('hidden') == -1));
				hasScrollY = (hasScrollableYContent && (overflowYStyle.indexOf('hidden') == -1));

				/** */
				const overflowXStyle = window.getComputedStyle(el).overflowX;
				let hasScrollableXContent = el.scrollWidth > el.clientWidth;
				hasScrollX = (hasScrollableXContent && (overflowXStyle.indexOf('hidden') == -1));

			}catch (e) {}

			Object.assign(state, { type, hasScroll, hasScrollX, hasScrollY });

			scrollX = el.scrollLeft <= 0 ? 0: el.scrollLeft;
			scrollY = el.scrollTop <= 0 ? 0: el.scrollTop;
			if(type === 'start'){
				Object.assign(state,{
					scrollX, scrollY,
					scrollMaxY: el.scrollHeight - (el.clientHeight + el.scrollY),
					scrollMaxX: el.scrollWidth - (el.scrollWidth + el.scrollX)
				})
			}

			try{
				scrollTop 			= el.scrollTop <= 0 ? 0: el.scrollTop;
				isScrollTop 		= el.scrollTop <= 0;
				isScrollBottom 	= el.scrollTop + el.clientHeight >= el.scrollHeight;
			}catch (e) {}

			try{
				isScrollMinX 		= el.scrollLeft <= 0;
				isScrollMaxX 		= el.scrollLeft + el.clientWidth >= el.scrollWidth;
				isScrollMinY 		= el.scrollTop <= 0;
				isScrollMaxY 		= el.scrollTop + el.clientHeight >= el.scrollHeight;
			}catch (e) {}


			Object.assign(state, { scrollTop, isScrollTop, isScrollBottom });
			Object.assign(state, { isScrollMinX, isScrollMaxX });
			Object.assign(state, { isScrollMinY, isScrollMaxY });
			return state;
		}

		/** useSwipeable */
		handlers = useSwipeable({
			trackMouse: true,
			touchEventOptions: { passive: false },
			preventScrollOnSwipe: true,
			scrollable: true,

			...swipeProps,
			onSwipeStart: rest => {
				rest.set && rest.set(set({ type: 'start' }));
				swipeProps.onSwipeStart && swipeProps.onSwipeStart({...rest, ...get(), getData: get })
			},
			onSwiped: rest => {
				rest.set && rest.set(set({ type: 'swiped' }))
				swipeProps.onSwiped && swipeProps.onSwiped({...rest, ...get(), getData: get })
			},
			onSwiping: rest => {
				rest.set && rest.set(set({ type: 'swiping' }));
				swipeProps.onSwiping && swipeProps.onSwiping({...rest, ...get(), getData: get })
			},
			onSwipedDown: rest => {
				rest.set && rest.set(set({ type: 'down' }));
				swipeProps.onSwipedDown && swipeProps.onSwipedDown({...rest, ...get(), getData: get })
			},
			onSwipedUp: rest => {
				rest.set && rest.set(set({ type: 'up' }));
				swipeProps.onSwipedUp && swipeProps.onSwipedUp({...rest, ...get(), getData: get })
			}
		});
	}


	const setSwiperRef = (node: any)=> {
		if(node){
			try{
				swipeRef.current = node;
				(typeof handlers?.ref === 'function')  && handlers?.ref(node);
				(typeof innerRef === 'function') && innerRef(node);
			}catch (e) {}
		}
	}

	return (
		<div {...props} {...handlers} ref={setSwiperRef}>
			{props?.children}
		</div>
	);
}

Swipeable.propTypes = {
	innerRef: PropTypes.func,
	children: PropTypes.node
}

// @ts-ignore
export default Swipeable;
