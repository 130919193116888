import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isObject from '../utils/isObject';
import { mapToCssModules, tagPropType } from '../utils/utils';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'vl'];
const stringOrNumberProp = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const columnProps = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    order: stringOrNumberProp,
    offset: stringOrNumberProp,
    right: stringOrNumberProp
  })
]);

const propTypes = {
  tag: tagPropType,
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
  vl: columnProps,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  widths: PropTypes.array,
};

const defaultProps = {
  tag: 'div',
  widths: colWidths,
};

const getColumnSizeClass = (isXs, colWidth, colSize) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'col' : `col-${colWidth}`;
  } else if (colSize === 'auto') {
    return isXs ? 'col-auto' : `col-${colWidth}-auto`;
  }

  return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
};


const Col = React.forwardRef(function Col({ children, id, tag: Tag, ...props }, ref) {
  Tag = Tag || 'div';
  let {
    className,
    cssModule,
    widths,
    ...attributes
  } = props;
  const colClasses = [];

  widths.forEach((colWidth, i) => {
    let columnProp = props[colWidth];

    delete attributes[colWidth];

    if (!columnProp && columnProp !== '') {
      return;
    }

    const isXs = !i;

    if (isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp.size);

      colClasses.push(mapToCssModules(clsx({
        [colClass]: columnProp.size || columnProp.size === '',
        [`order${colSizeInterfix}${columnProp.order}`]: columnProp.order || columnProp.order === 0,
        [`offset${colSizeInterfix}${columnProp.offset}`]: columnProp.offset || columnProp.offset === 0,
        [`offset-right${colSizeInterfix}${columnProp.right}`]: columnProp.right || columnProp.right === 0
      }), cssModule));
    } else {
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp);
      colClasses.push(colClass);
    }
  });

  if (!colClasses.length) {
    colClasses.push('col');
  }

  const classes = mapToCssModules(clsx(
    className,
    colClasses
  ), cssModule);

  children = (children === NaN) || (children === null) ? '':children;
  return React.createElement(Tag, {
    ...attributes ,
    id,
    ref,
    className: classes,
  }, children);
});

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default Col;
