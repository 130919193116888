import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import View from '../View';
import Page from '../Page';

const PageLayout = React.forwardRef(function PageLayout({ $self, getBlock, id, style, viewTag, webType, noLeft, noRight, noSide, setClasses, className, classToggle, children, ...other}, ref) {
  let headPos     = [];
  let topPos      = [];
  let mainsPos    = [];
  let bottomPos   = [];

  getBlock = getBlock || (()=>{});

  let props = { ...other, $self };
  if (!(noRight || noSide)) {
    headPos.push(`${webType}.right`)
  }

  if (!(noSide)) {
    headPos.push(`${webType}.head`)
    topPos.push(`${webType}.top` )
    mainsPos.push(`${webType}.footer`)
    bottomPos.push(`${webType}.bottom`)
  }

  if (!(noLeft || noSide)) {
    headPos.push(`${webType}.left`)
  }

  try {
    const touchStart = e => {
      try {
        this.firstClientX = e.touches[0].clientX;
      }catch (e) {}
      try {
        this.firstClientY = e.touches[0].clientY;
      }catch (e) {}
    };

    const preventTouch = e => {
      try {
        const minValue = 5; // threshold
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
          e.preventDefault();
          e.returnValue = false;
          return false;
        }
      }catch (e) {

      }
    };

    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
  } catch (e) {}

  return (
    <React.Fragment>
      {getBlock(headPos, props)}
      <View tag={viewTag || 'div' } main className={clsx('contentWrapper', className)} {...{ id, style, ref}}>
        {getBlock(topPos, other)}
        <Page>
          {children}
          {getBlock(mainsPos, other)}
        </Page>
      </View>
      {getBlock(bottomPos, other)}
    </React.Fragment>
  );
});

PageLayout.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  webType: PropTypes.node,
  getBlock: PropTypes.func,
  className: PropTypes.node,
  LeftSide: PropTypes.node,
  RightSide: PropTypes.node,
  children: PropTypes.node,
  navBar: PropTypes.bool,
  noLeft: PropTypes.bool,
  noRight: PropTypes.bool,
  noSide: PropTypes.bool
};

export default PageLayout;
