import { graphql } from "react-apollo";
import ARTICLES_QUERY from "../graphql/articlesQuery.graphql";
import { compose, withProps } from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";
/**
 * withArticles
 * @param input
 */ export const withArticles = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
            let { articles: { variables  }  } = param;
            return {
                ...variables
            };
        }), graphql(ARTICLES_QUERY, {
            options: (param)=>{
                let { view_limit , protected: p , page , pageSize , orderBy , direction , filter , fetchPolicy  } = param;
                return {
                    fetchPolicy: fetchPolicy || null,
                    variables: {
                        viewLimit: !!view_limit,
                        protected: p,
                        page,
                        pageSize,
                        orderBy,
                        direction,
                        filter
                    }
                };
            },
            props (param) {
                let { data , ownProps: { articles  }  } = param;
                let { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  } = data || {};
                articles.fetchMore = fetchMore;
                if (!loading && res) {
                    articles.update(res);
                }
                return {
                    loading,
                    articlesRefetch: refetch,
                    articlesSubscribeToMore: subscribeToMore,
                    articlesMore: fetchMore,
                    articlesUpdate: updateQuery,
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withArticles;
