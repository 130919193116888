import { graphql } from "react-apollo";
import { compose, withProps } from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";
import { MENU_QUERY } from "../graphql";
export const withMenus = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), graphql(MENU_QUERY, {
            options: (param)=>{
                let { name  } = param;
                return {
                    variables: {
                        name
                    }
                };
            },
            props (param) {
                let { data: { loading , menu: contents , refetch , error , updateQuery , fetchMore , subscribeToMore  } , ownProps: { menu  }  } = param;
                if (!loading && contents) {
                    menu.save(contents);
                }
                return {
                    loading,
                    menusRefetch: refetch,
                    menusMore: fetchMore,
                    menusSubscribeToMore: subscribeToMore,
                    updateQuery,
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
