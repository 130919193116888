"use strict";
module.exports = {
    "en": {
        "breadcrumb": {
            "list": "List of members",
            "profile": "Account information"
        },
        "home": "Home",
        "login": "Login",
        "register": "Register",
        "free-register": "Register for free",
        "logout": "Logout",
        "next": "Next",
        "verification": "Verification",
        "resetPass": {
            "title": "Reset Password",
            "meta": "Forgot password page",
            "errorMsg": "Reset password failed!",
            "form": {
                "title": "Reset Password",
                "msg": "Use {{value}} or more characters and combine letters, numbers, and symbols",
                "fldEmail": "Email",
                "btnSubmit": "Complete",
                "field": {
                    "pass": "New password",
                    "passConf": "Re-enter password"
                }
            }
        },
        "pass": {
            "title": "Retrieve Password",
            "meta": "Forgot password page",
            "errorMsg": "Reset password failed!",
            "form": {
                "submitMsg": "The password reset instructions have been sent to your email.",
                "title": "Retrieve Password",
                "fldEmail": "Email",
                "field": {
                    "name": "Email address"
                },
                "btnSubmit": "Retrieve Password"
            }
        },
        "label": {
            "list": "List of members",
            "login": "Login",
            "register": "Register",
            "profile": "Account",
            "free-register": "Register for free",
            "forgotPass": "Retrieve password",
            "reset": "Reset password",
            "logout": "Logout",
            "inbox": "Inbox",
            "contact": "Contact"
        },
        "role": {
            "label": "Role",
            "all": "All",
            "authenticated": "Member",
            "administrator": "Administrator",
            "root": "Super administrator",
            "moderator": "Moderator",
            "host": "Host",
            "business": "Business",
            "professional": "Professional",
            "sale": "Sale"
        },
        "users": {
            "column": {
                "name": "Name",
                "email": "Email",
                "role": "Role",
                "active": "Active",
                "actions": "Actions"
            },
            "btn": {
                "add": "Add member"
            },
            "list": {
                "title": "List of members"
            }
        },
        "update": {
            "saved": "Update successful",
            "errorMsg": "Unidentified error"
        },
        "userEdit": {
            "form": {
                "field": {
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full name",
                    "firstName": "First name",
                    "lastName": "Last name",
                    "sex": "Gender",
                    "birthday": "Birthday",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone",
                    "pass": "Password",
                    "password": "Current password",
                    "new_password": "New password",
                    "Confirm_password": "Re-enter password",
                    "username": "Username",
                    "mail": "Email",
                    "active": "Active",
                    "subscribe": "Receive promotions and news related to {{value}}"
                }
            }
        },
        "profile": {
            "label": "Account",
            "title": "Account information",
            "breadcrumb": "Account information",
            "headerText": "Account information",
            "meta": "Account information page",
            "loadMsg": "Loading...",
            "errorMsg": "No current user logged in",
            "editProfileText": "Edit profile",
            "company": {
                "pattern": "Pattern number",
                "serial": "Serial number",
                "name": "Company name",
                "code": "Tax code",
                "address": "Address",
                "phone": "Phone number",
                "fax": "Fax number",
                "mail": "Email",
                "represent": "Representative",
                "bank_no": "Account number",
                "bank_name": "Bank name",
                "account_holder": "Account holder"
            },
            "card": {
                "title": "Account information",
                "group": {
                    "name": "Account",
                    "email": "Email",
                    "role": "Role",
                    "full": "Full name"
                },
                "fldEmail": "Email",
                "btnSubmit": "Send instructions"
            },
            "form": {
                "field": {
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full name",
                    "firstName": "First name",
                    "lastName": "Last name",
                    "sex": "Gender",
                    "birthday": "Birthday",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone",
                    "password": "Current password",
                    "new_password": "New password",
                    "Confirm_password": "Re-enter password",
                    "username": "Username",
                    "mail": "Email",
                    "subscribe": "Receive promotions and news related to {{value}}"
                }
            },
            "btn": {
                "save": "Save changes",
                "fbBtn": "Connect with Facebook account",
                "googleBtn": "Connect with Google account",
                "githubBtn": "Connect with Github account",
                "linkedinBtn": "Connect with LinkedIn account"
            }
        }
    },
    "vi": {
        "title": "",
        "breadcrumb": {
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "profile": "Th\xf4ng tin t\xe0i khoản"
        },
        "list": {
            "btn": {
                "avatar": "Thay đổi avatar",
                "profile": "Thay đổi Th\xf4ng tin th\xe0nh vi\xean"
            }
        },
        "home": "Trang chủ",
        "login": "Đăng nhập",
        "register": "Đăng k\xfd",
        "forgotPassword": "Lấy Lại Mật Khẩu",
        "free-register": "Đăng k\xfd miễn ph\xed",
        "logout": "Đăng xuất",
        "next": "Tiếp Theo",
        "verification": "X\xe1c Minh",
        "resetPass": {
            "title": "Tạo Lại Mật Khẩu Mới",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "title": "Tạo Lại Mật Khẩu Mới",
                "msg": "Sử dụng {{value}} k\xfd tự trở l\xean v\xe0 kết hợp chữ c\xe1i, chữ số v\xe0 biểu tượng",
                "fldEmail": "Email",
                "btnSubmit": "Ho\xe0n Th\xe0nh",
                "field": {
                    "pass": "Mật khẩu mới",
                    "passConf": "Nhập lại mật khẩu"
                }
            }
        },
        "pass": {
            "title": "Lấy Lại Mật Khẩu",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "submitMsg": "Hướng dẫn đặt lại mật khẩu của bạn đ\xe3 được gửi qua email cho bạn.",
                "title": "Lấy Lại Mật Khẩu",
                "fldEmail": "Email",
                "field": {
                    "name": "Địa chỉ EMail"
                },
                "btnSubmit": "Lấy Lại Mật Khẩu"
            }
        },
        "label": {
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "login": "Đăng nhập",
            "register": "Đăng k\xfd",
            "profile": "T\xe0i khoản",
            "free-register": "Đăng k\xfd miễn ph\xed",
            "forgotPass": "T\xecm mật khẩu",
            "reset": "Đặt lại mật khẩu",
            "logout": "Đăng xuất",
            "inbox": "Hộp thư",
            "contact": "Li\xean hệ"
        },
        "role": {
            "label": "Quyền",
            "all": "Tất cả",
            "authenticated": "Th\xe0nh vi\xean",
            "root": "Quản trị cấp cao",
            "administrator": "Quản trị cấp cao",
            "admin": "Quản trị",
            "moderator": "Quản trị",
            "host": "Chủ nh\xe0",
            "business": "Doanh nghiệp",
            "professional": "Chuy\xean nghiệp",
            "sale": "Khuyến m\xe3i"
        },
        "users": {
            "title": "Danh s\xe1ch th\xe0nh vi\xean",
            "column": {
                "name": "T\xean t\xe0i khoản",
                "roles": "Ph\xe2n quyền",
                "email": "Email",
                "role": "Vai tr\xf2",
                "active": "Đang hoạt động",
                "actions": "H\xe0nh động"
            },
            "btn": {
                "add": "Th\xeam th\xe0nh vi\xean"
            },
            "list": {
                "title": "Danh s\xe1ch th\xe0nh vi\xean"
            }
        },
        "update": {
            "saved": "Cập nhật th\xe0nh c\xf4ng",
            "errorMsg": "Lỗi kh\xf4ng x\xe1c định"
        },
        "userEdit": {
            "form": {
                "field": {
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Phone",
                    "pass": "Mật khẩu",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "active": "Hoạt động",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            }
        },
        "profile": {
            "label": "T\xe0i khoản",
            "title": "Th\xf4ng tin t\xe0i khoản",
            "breadcrumb": "Th\xf4ng tin t\xe0i khoản",
            "headerText": "Th\xf4ng tin t\xe0i khoản",
            "meta": "Trang th\xf4ng tin t\xe0i khoản",
            "loadMsg": "Loading...",
            "errorMsg": "Kh\xf4ng c\xf3 người d\xf9ng hiện tại n\xe0o đăng nhập",
            "editProfileText": "Chỉnh sửa hồ sơ",
            "company": {
                "pattern": "Mẫu số",
                "serial": "K\xfd hiệu",
                "name": "T\xean c\xf4ng ty",
                "code": "M\xe3 số thuế",
                "address": "Địa chỉ",
                "phone": "Điện thoại",
                "fax": "Số Fax",
                "mail": "Email",
                "represent": "Người đại diện",
                "bank_no": "Số t\xe0i khoản",
                "bank_name": "T\xean ng\xe2n h\xe0ng",
                "account_holder": "Chủ t\xe0i khoản"
            },
            "form": {
                "field": {
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Phone",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            },
            "btn": {
                "save": "Lưu thay đổi",
                "fbBtn": "Kết nối với t\xe0i khoản Facebook",
                "googleBtn": "Kết nối với t\xe0i khoản Google",
                "githubBtn": "Kết nối với t\xe0i khoản github",
                "linkedinBtn": "Kết nối với t\xe0i khoản linkedin"
            }
        },
        "notify": {
            "odd": {
                "title": "Danh s\xe1ch {{value}} user"
            },
            "even": {
                "title": "Danh s\xe1ch {{value}} users"
            },
            "unpublished": {
                "odd": {
                    "title": "Danh s\xe1ch {{value}} user ẩn"
                },
                "even": {
                    "title": "Danh s\xe1ch {{value}} users ẩn"
                }
            }
        }
    }
};
