import withProps from "recompose/withProps";
const stylesHeaders = {
    stylesHeaders: {
        "& [data-arrow-end], & [data-arrow-start]": {
            "boxShadow": "0px 0px 3px rgba(176, 176, 176, 0.5)",
            position: "absolute",
            top: 0
        },
        "& [data-arrow-start]": {
            left: 0
        },
        "& [data-arrow-end]": {
            right: 0
        }
    }
};
export default withProps({
    stylesHeaders: {
        "& [data-arrow-end], & [data-arrow-start]": {
            "boxShadow": "0px 0px 3px rgba(176, 176, 176, 0.5)",
            position: "absolute",
            top: 0
        },
        "& [data-arrow-start]": {
            left: 0
        },
        "& [data-arrow-end]": {
            right: 0
        }
    }
});
