import React from "react";
import loadable from "next/dynamic";
const ArticleView = loadable(()=>import("./Article").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Article")
            ]
    }
});
export default ArticleView;
