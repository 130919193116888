class Menu {
    /**
   * instanse
   */ static instanse() {
        return new this();
    }
    /**
   * save
   */ save(param) {
        let { name , items =[]  } = param;
        this.name = name;
        this.items = items.sort(function() {
            let a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, b = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
            return a.weight - b.weight;
        });
    }
}
export { Menu as default };
