import React from "react";
import moment from "moment-timezone";
export default function RootComponent(param) {
    let { children , session , ...props } = param;
    let { key , value  } = session || {};
    let guess = moment.tz.guess();
    setCookie("tz", guess);
    /** preload cookie*/ React.useEffect(()=>{
        if (key && value && !hasCookie(key)) {
            let allCookies = getCookies();
            let keys = Object.keys(allCookies) || [];
            keys.filter((k)=>new RegExp(RegExp("(SESS|SSESS)(.*)", "s")).test(k)).map(function(value) {
                deleteCookie(value);
            });
            setCookie(key, value);
        }
    }, [
        key,
        value
    ]);
    return children;
}
