import { graphql } from "react-apollo";
import VIDEOS_QUERY from "../graphql/videosQuery.graphql";
import { compose, withProps } from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";
/**
 * withVideos
 * @param input
 */ export const withVideos = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
            let { videos: { variables  }  } = param;
            return {
                ...variables
            };
        }), graphql(VIDEOS_QUERY, {
            options: (param)=>{
                let { view_limit , protected: p , page , pageSize , orderBy , direction , filter  } = param;
                return {
                    variables: {
                        viewLimit: !!view_limit,
                        protected: p,
                        page,
                        pageSize,
                        orderBy,
                        direction,
                        filter
                    }
                };
            },
            props (param) {
                let { data , ownProps: { videos  }  } = param;
                let { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  } = data || {};
                videos.fetchMore = fetchMore;
                if (!loading && res) {
                    videos.update(res);
                }
                return {
                    loading,
                    videosRefetch: refetch,
                    videosSubscribeToMore: subscribeToMore,
                    videosMore: fetchMore,
                    videosUpdate: updateQuery,
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withVideos;
