import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import WidgetDiv from './WidgetDiv';

const WidgetBox = ({
                     size,
                     color,
                     title,
                     style,
                     styleTitle,
                     top,
                     bottom,
                     tagsTitle,
                     condition,
                     tagsInnerTitle,
                     children,
                     className,
                     classTitle,
                     classNameTitle,
                     classNameBody,
                     classNameTop,
                     classNameBottom,
                     onClick
                   }) => (
  <>
    <WidgetDiv
      onClick={onClick}
      style={style}
      className={clsx(
        className,
        'widget',
        size ? `widget-${size}` : '',
        color ? `widget-color widget-${color}` : ''
      )}
    >
      {!!title && (
        <WidgetDiv tag={tagsTitle} style={styleTitle} className={clsx(classNameTitle, 'widget-title')}>
          {(typeof title !== 'string') && <>{title}</>}
          {(typeof title === 'string') && <WidgetDiv tag={tagsInnerTitle} className={classTitle}>{title}</WidgetDiv>}
        </WidgetDiv>
      )}
      {condition && <>
        { (!!top) && <><WidgetDiv className={clsx(classNameTop, 'widget-top')}>{top}</WidgetDiv></>}
        { (!!children) && <><WidgetDiv className={clsx(classNameBody, 'widget-body')}>{children}</WidgetDiv></>}
        { (!!bottom) && <><WidgetDiv className={clsx(classNameBottom, 'widget-bottom')}>{bottom}</WidgetDiv></>}
      </>}
    </WidgetDiv>
  </>
);

WidgetBox.propTypes = {
  t: PropTypes.func,
  style: PropTypes.object,
  size: PropTypes.node,
  color: PropTypes.node,
  title: PropTypes.node,
  top: PropTypes.node,
  bottom: PropTypes.node,
  className: PropTypes.node,
  classTitle: PropTypes.node,
  tagsTitle: PropTypes.node,
  classNameInnerTitle: PropTypes.node,
  classNameTitle: PropTypes.node,
  classNameTop: PropTypes.node,
  classNameBody: PropTypes.node,
  children: PropTypes.node,
  classNameBottom: PropTypes.node
};

WidgetBox.defaultProps = {
  condition: true,
  tagsTitle: 'div',
  tagsInnerTitle: 'span'
};

export default WidgetBox;
