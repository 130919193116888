import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const MenuItem = React.forwardRef(function MenuItem({ children, id, tag, className, ...props }, ref) {
  tag = tag || 'li'
  return React.createElement(tag, {
    ...props ,
    id,
    ref,
    className: clsx( 'nav-item', className),
  }, children);
});

MenuItem.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * If `true`, the MenuItem will use raised styling.
   */
  raised: PropTypes.bool,
};

export default MenuItem;
