const cookie = require('cookies-next');
const settings = require('@gqlapp/config').default;
// const regex = new RegExp(/(SESS|SSESS)(.*)/s);
const getOption = (options)=>{
  try{
    if(settings.app.domainCookieAll){
      options = { ...options, domain: `.${window.location.hostname}` }
    }
  }catch (e) {}
  return options;
}

setCookie     = function (){
  let [key, value, options ] = arguments;
  if(key && value) {
    cookie.setCookie(key, value, getOption(options))
  }
}

getCookies 		= (options) => cookie.getCookies(getOption(options));
getCookie			= (key, options) => cookie.getCookie(key, getOption(options));
setCookies		= (key, data, options) => (key && data) ? cookie.setCookies(key, data, getOption(options)): null;
deleteCookie	= (key, options) => cookie.deleteCookie(key, getOption(options));
removeCookies	= (key, options) => cookie.removeCookies(key, getOption(options));
hasCookie			= (key, options) => cookie.hasCookie(key, getOption(options));
checkCookies	= (key, options) => cookie.checkCookies(key, getOption(options));
