import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from "clsx";
import { mapToCssModules } from '../utils/utils'

const WidgetDiv = ({className, cssModule, tag: Tag, ...attributes} ) =>{
  const classes = mapToCssModules(clsx(className), cssModule);
  return (
    <Tag {...attributes} className={classes} />
  );
}

WidgetDiv.propTypes = {
  className: PropTypes.node,
  children: PropTypes.node
};

WidgetDiv.defaultProps = {
  tag: 'div'
};

export default WidgetDiv;
