import React from "react";
import loadable from "next/dynamic";
const PhotoView = loadable(()=>import("./Photo").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Photo")
            ]
    }
});
export default PhotoView;
