import withProps from "recompose/withProps";
import React from "react";
let timeout = null;
const withLoading = withProps((param)=>{
    let { loading , loadbar: { done  }  } = param;
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
        if (!loading) {
            done();
        }
    }, 100);
});
export default withLoading;
