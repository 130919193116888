const errCatch = ()=>function() {
        let { graphQLErrors , errorMsg , ...props } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        let { message , type , _errors  } = errorMsg || props || {};
        let { errorMsg: eMsg  } = _errors || {};
        message = message || errorMsg || eMsg || "";
        if (graphQLErrors && graphQLErrors.length) {
            graphQLErrors.map((param)=>{
                let { message  } = param;
                return msg({
                    message: "".concat(message).t(),
                    type: type || "danger"
                });
            });
        } else if (message) {
            msg({
                message: "".concat(message).t(),
                type: type || "danger"
            });
        }
    };
export default errCatch;
