import Settings from "./settings";
const instance = Settings.createInstance();
// @ts-ignore
instance.createInstance = Settings.createInstance;
export const isHttps = (value)=>value && /^(https)/i.test(value);
const get = ()=>{
    let settingsElement = {};
    if ("object" !== "undefined") {
        settingsElement = $$('head > script[type="application/json"][data-selector="tryv-settings-json"]');
    }
    settingsElement = settingsElement || {};
    if (typeof settingsElement.textContent != "undefined") {
        settingsElement = JSON.parse(settingsElement.textContent);
    }
    return settingsElement;
};
/** react api */ const api = ()=>{
    let { apiURL  } = get();
    return __SERVER__ ? process.env.API_SERVER_URL : apiURL || "/api/graphql";
};
/** Version */ const useVersion = ()=>{
    let { version  } = get();
    return __SERVER__ ? process.env.VERSION : version;
};
export const apiUrl = api();
// eslint-disable-next-line react-hooks/rules-of-hooks
export const version = useVersion();
// @ts-ignore
export default instance;
