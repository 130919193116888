import React from "react";
import loadable from "next/dynamic";
const ListView = loadable(()=>import("./Video").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Video")
            ]
    }
});
export default ListView;
