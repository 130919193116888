class Action {
    /**
   *
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return new this(props);
    }
    test() {}
    set callback(value) {
        this._callback = value;
    }
    get callback() {
        return this._callback;
    }
    constructor(props = null){
        this._callback = ()=>{};
    }
}
export default Action;
