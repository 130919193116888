import Entities from "@gqlapp/core-common/Entities";
import Video from "./Video";
class Videos extends Entities {
    /**
   * instanse
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return new this(props);
    }
    constructor(props = {}){
        super(props);
        this.interface = Video;
    }
}
export { Videos as default };
