import Entities from "@gqlapp/core-common/Entities";
import Article from "./Article";
class Articles extends Entities {
    /**
   * instanse
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return new this(props).save([]);
    }
    constructor(props = {}){
        super(props);
        this.interface = Article;
    }
}
export { Articles as default };
